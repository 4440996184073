


































import { Component, Vue } from 'vue-property-decorator'
@Component
export default class extends Vue {
  baseInfomation = [
    {
      label: '头像',
      key: 'avatar_collect_num'
    },
    {
      label: '昵称',
      key: 'nickname_collect_num'
    },
    {
      label: '账号',
      key: 'account_collect_num'
    },
    {
      label: '手机号',
      key: 'phone_collect_num'
    },
    // {
    //   label: '性别',
    //   key: 'sex_collect_num'
    // },
    // {
    //   label: '生日',
    //   key: 'birthday_collect_num'
    // },
    {
      label: '姓名',
      key: 'realname_collect_num'
    },
    {
      label: '身份证号',
      key: 'idcard_collect_num'
    }
  ]

  ohterList = [
    // {
    //   label: '位置',
    //   key: 'location_collect_num'
    // },
    {
      label: '图片',
      key: 'dynamic_collect_num'
    }
  ]

  toDetail(item: any) {
    this.$router.push({
      path: '/informationDetail',
      query: { key: item.key }
    })
  }
}
